var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "form", staticClass: "comment-form" }, [
    _c(
      "progress",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.inProgress,
            expression: "inProgress",
          },
        ],
        staticClass: "progress is-info is-small",
        attrs: { max: "100" },
        domProps: { value: _vm.percentProgress },
      },
      [_vm._v("\n    " + _vm._s(_vm.percentProgress) + "%\n  ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "control" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.commentBody,
              expression: "commentBody",
            },
          ],
          ref: "textbox",
          staticClass: "textarea",
          class: { "is-danger": _vm.hasError },
          attrs: { placeholder: _vm.placeholderText },
          domProps: { value: _vm.commentBody },
          on: {
            "tribute-replaced": _vm.updateCommentBody,
            keyup: _vm.resizeTextArea,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.commentBody = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasError,
                expression: "hasError",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v("This cannot be blank")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.files.length > 0,
            expression: "this.files.length > 0",
          },
        ],
        staticClass: "field is-grouped is-grouped-multiline staged_files",
      },
      _vm._l(this.files, function (file, fileIndex) {
        return _c("div", { staticClass: "control" }, [
          _c("div", { staticClass: "tags has-addons" }, [
            _c("div", { staticClass: "tag is-dark" }, [
              _vm._v("\n          " + _vm._s(file.name) + "\n        "),
            ]),
            _vm._v(" "),
            _c("a", {
              staticClass: "tag is-delete",
              on: {
                click: function ($event) {
                  return _vm.removeFile(fileIndex)
                },
              },
            }),
          ]),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "control buttons action-buttons" }, [
        _c(
          "button",
          {
            staticClass: "button is-info is-small",
            attrs: { disabled: !_vm.activeButton },
            on: { click: _vm.submitComment },
          },
          [_vm._v("\n        Add comment\n      ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown is-hoverable is-hidden-mobile" }, [
          _c("div", { staticClass: "dropdown-trigger" }, [
            _c(
              "button",
              {
                staticClass: "button is-info is-small",
                attrs: { "aria-haspopup": "true", disabled: !_vm.activeButton },
              },
              [_vm._m(0)]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeButton,
                  expression: "activeButton",
                },
              ],
              staticClass: "dropdown-menu",
              attrs: { role: "menu" },
            },
            [
              _c("div", { staticClass: "dropdown-content" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    on: { click: _vm.submitAndToggleComplete },
                  },
                  [
                    _vm._v(
                      "\n              Add comment and mark\n              "
                    ),
                    _vm.isComplete
                      ? _c("span", [
                          _vm._v(
                            "\n                incomplete\n              "
                          ),
                        ])
                      : _c("span", [
                          _vm._v("\n                complete\n              "),
                        ]),
                  ]
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "file is-light is-small mr-2" }, [
          _c("label", { staticClass: "file-label" }, [
            _c("input", {
              ref: "fileInput",
              staticClass: "file-input",
              attrs: { type: "file", multiple: "" },
              on: { change: _vm.updateFileSelection },
            }),
            _vm._v(" "),
            _vm._m(1),
          ]),
        ]),
        _vm._v(" "),
        _vm.showEscalateViaEmail
          ? _c(
              "a",
              {
                staticClass: "button is-small is-light",
                on: { click: _vm.flagItem },
              },
              [
                _c("span", [_vm._v("\n          Flag\n        ")]),
                _vm._v(" "),
                _vm._m(2),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "button is-small is-light",
            on: { click: _vm.hideCommentForm },
          },
          [_vm._v("Hide")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", {
        staticClass: "fas fa-angle-down",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "file-cta" }, [
      _c("i", { staticClass: "fas fa-paperclip" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ml-2" }, [
      _c("i", { staticClass: "far fa-flag" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }