var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canAssign == "true" && !_vm.alreadyAssigned
    ? _c("div", { staticClass: "panel-block" }, [
        _c(
          "a",
          {
            staticClass: "has-text-centered horizontal-spacer",
            attrs: { rel: "nofollow" },
            on: { click: _vm.assignUser },
          },
          [_vm._v("\n    Assign me\n  ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }