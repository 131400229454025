var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "checklist-progress-component",
      on: { click: _vm.changeSortOrder },
    },
    [
      _c("span", { staticClass: "checklist-descriptor" }, [
        _vm._v("Progress:"),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "checklist-ratio",
          class: { "tag is-success": _vm.completeStatus },
        },
        [_vm._v("\n    " + _vm._s(_vm.progressRatio || _vm.init) + "\n  ")]
      ),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "fas fa-tasks",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }