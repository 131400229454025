var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comment-edit-form" }, [
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "control" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.commentBody,
              expression: "commentBody",
            },
          ],
          ref: "textbox",
          staticClass: "textarea",
          class: { "is-danger": _vm.hasError },
          attrs: { rows: "2" },
          domProps: { value: _vm.commentBody },
          on: {
            "tribute-replaced": _vm.updateCommentBody,
            keyup: _vm.resizeTextArea,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.commentBody = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasError,
                expression: "hasError",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v("This cannot be blank")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.files.length > 0,
            expression: "this.files.length > 0",
          },
        ],
        staticClass: "field is-grouped is-grouped-multiline staged_files",
      },
      _vm._l(this.files, function (file, fileIndex) {
        return _c("div", { staticClass: "control" }, [
          _c("div", { staticClass: "tags has-addons" }, [
            _c(
              "div",
              {
                staticClass: "tag",
                class: _vm.fileIndexRemoved(file.signed_id)
                  ? "is-danger"
                  : "is-dark",
              },
              [_vm._v("\n          " + _vm._s(file.name) + "\n        ")]
            ),
            _vm._v(" "),
            _c("a", {
              staticClass: "tag is-delete",
              on: {
                click: function ($event) {
                  return _vm.toggleRemoveFile(file.signed_id)
                },
              },
            }),
          ]),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "control buttons action-buttons" }, [
        _c(
          "button",
          {
            staticClass: "button is-info is-small",
            attrs: { disabled: _vm.hasError },
            on: { click: _vm.submitForm },
          },
          [_vm._v("\n        Update comment\n      ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "button is-small", attrs: { href: _vm.editURL } },
          [_vm._v("Advanced edit")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-small", on: { click: _vm.hideForm } },
          [_vm._v("Cancel")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }