var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "highlight template-item" }, [
    this.templateItem._destroy
      ? _c("div", [
          _c("div", [
            _c("span", { staticClass: "is-italic" }, [
              _vm._v('"' + _vm._s(_vm.templateItem.name) + '"'),
            ]),
            _vm._v(" will be deleted\n      "),
            _c(
              "button",
              {
                staticClass: "button is-small is-light",
                on: { click: _vm.undoDelete },
              },
              [_vm._v("Undo")]
            ),
          ]),
        ])
      : _c("div", [
          _c("div", { staticClass: "field has-addons" }, [
            _c("div", { staticClass: "control draggable" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  class: [_vm.errorFor("name") ? "is-danger" : "is-static"],
                },
                [_vm._v("\n          " + _vm._s(_vm.index + 1) + "\n        ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "control is-expanded" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.templateItem.name,
                    expression: "templateItem.name",
                  },
                ],
                ref: "name",
                staticClass: "input",
                class: { "is-danger": _vm.errorFor("name") },
                attrs: { type: "text", placeholder: "Checklist item" },
                domProps: { value: _vm.templateItem.name },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addNewTemplateItem.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.templateItem, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errorFor("name"),
                      expression: "errorFor('name')",
                    },
                  ],
                  staticClass: "help is-danger",
                },
                [_vm._v("This cannot be blank")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDescription,
                  expression: "showDescription",
                },
              ],
              staticClass: "field",
            },
            [
              _c("div", { staticClass: "control" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.templateItem.description,
                      expression: "templateItem.description",
                    },
                  ],
                  ref: "description",
                  staticClass: "textarea",
                  class: { "is-danger": _vm.errorFor("description") },
                  attrs: { rows: "3", placeholder: "Description" },
                  domProps: { value: _vm.templateItem.description },
                  on: {
                    "tribute-replaced": _vm.updateTemplateItemDescription,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.templateItem,
                        "description",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c(
              "button",
              {
                staticClass: "template-item button is-small",
                class: [_vm.templateItem.mandatory ? "is-info" : "is-light"],
                on: { click: _vm.toggleMandatory },
              },
              [_vm._v("Mandatory")]
            ),
            _vm._v(" "),
            _vm.showDescription
              ? _c(
                  "button",
                  {
                    staticClass: "template-item button is-small is-danger",
                    on: { click: _vm.toggleDescription },
                  },
                  [_vm._v("\n        Remove Description\n      ")]
                )
              : _c(
                  "button",
                  {
                    staticClass: "template-item button is-small is-light",
                    on: { click: _vm.toggleDescription },
                  },
                  [_vm._v("\n        Add description\n      ")]
                ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "template-item button is-small is-light",
                on: { click: _vm.moveUp },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("span", [_vm._v("\n          Move up\n        ")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "template-item button is-small is-light",
                on: { click: _vm.moveDown },
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c("span", [_vm._v("\n          Move down\n        ")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "template-item button is-small is-danger",
                on: { click: _vm.remove },
              },
              [_vm._v("Delete")]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "fa fa-arrow-up",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "fa fa-arrow-down",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }