var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tutorial-container" },
    [
      _c("tutorial-progress"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tutorial-panes" },
        [
          _c("pane", { attrs: { index: "0" } }, [_c("overview")], 1),
          _vm._v(" "),
          _c("pane", { attrs: { index: "1" } }, [_c("templates")], 1),
          _vm._v(" "),
          _c("pane", { attrs: { index: "2" } }, [_c("checklists")], 1),
          _vm._v(" "),
          _c("pane", { attrs: { index: "3" } }, [_c("teams")], 1),
          _vm._v(" "),
          _c(
            "pane",
            { attrs: { index: "4" } },
            [
              _c("get-started", {
                attrs: { "checklist-url": _vm.checklistUrl },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }