var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box content" },
    [
      _c("h3", { staticClass: "is-size-5 has-text-weight-semibold" }, [
        _vm._v("\n    Welcome to Hoshon!\n  "),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    Build simple checklists and manage your team's processes using Hoshon.\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    To make the most of Hoshon, you'll need to be familiar with a few key concepts:\n  "
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("next-pane"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Templates")]),
      _vm._v(" "),
      _c("li", [_vm._v("Checklists")]),
      _vm._v(" "),
      _c("li", [_vm._v("Teams")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }