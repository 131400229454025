var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "edit-task-form" }, [
    _c("div", { staticClass: "field" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name",
          },
        ],
        staticClass: "input",
        attrs: { type: "text" },
        domProps: { value: _vm.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.name = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "a",
        {
          staticClass: "button is-small is-info",
          on: { click: _vm.submitForm },
        },
        [_vm._v("Save changes")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "button is-small", on: { click: _vm.hideForm } }, [
        _vm._v("Cancel"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }