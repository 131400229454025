var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checklist" },
    [
      _c("checklist-item-list", { attrs: { data: this.checklistItemData } }),
      _vm._v(" "),
      _vm.hasTasks
        ? _c(
            "h2",
            {
              staticClass: "one-off-title-bottom is-size-4",
              class: { "one-off-title-top": _vm.hasChecklistItems },
            },
            [_vm._v("\n    One off tasks\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("task-list", { attrs: { data: this.taskData } }),
      _vm._v(" "),
      this.canEdit ? _c("task-form") : _vm._e(),
      _vm._v(" "),
      _vm.checklistComplete ? _c("checklist-easter-egg") : _vm._e(),
      _vm._v(" "),
      !_vm.checklistComplete && this.canEdit && _vm.hasDeadline
        ? _c("div", { staticClass: "bump-deadline buttons" }, [
            _c("a", { staticClass: "button", attrs: { href: _vm.editURL } }, [
              _vm._v("Edit deadline"),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }