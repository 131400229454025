// Makes the following assumptions:
// That there is a top level array of hashes called `list`
//
// Each hash has the following fields:
//
// {
//   "comment_status": integer,
//   "comments": [{
//     "body": string,
//     "can_edit": boolean,
//     "created_at": datetime,
//     "edited": boolean,
//     "files": [],
//     "id": string,
//     "owner_name": string,
//     "processed_body": string,
//     "showForm": boolean,
//     "updated_at": datetime
//   }],
//   "id": string,
//   "inProgress": boolean,
//   "inProgressPercentage": integer,
// }
//

export default {
  mutations: {
    addNewComment(state, payload) {
      const commentable = state.list[payload.commentableIndex]
      let comment = payload.data
      comment.showForm = false

      commentable.comments.push(comment)
      commentable.inProgress = false
      commentable.inProgressPercentage = 0
    },
    addTemporaryComment(state, payload) {
      const commentable = state.list[payload.commentableIndex]
      let comment = payload.data
      comment.showForm = false

      commentable.comments.push(comment)
    },
    removeComment(state, payload) {
      state.list[payload.commentableIndex].comments.splice(payload.commentIndex, 1)
    },
    removeLastComment(state, payload) {
      const commentable = state.list[payload.commentableIndex]
      commentable.comments.splice(-1, 1)
    },
    updateComment(state, payload) {
      state.list[payload.commentableIndex].comments.splice(payload.commentIndex, 1, payload.data)
    },
    updateCommentStatus(state, newState) {
      // For displaying or hiding the comments
      for (var i = state.list.length - 1; i >= 0; i--) {
        state.list[i].comment_status = newState
      }
    },
    updateExpandedComment(state, payload) {
      state.list[payload.commentableIndex].comment_status = payload.value
    },
    updateUploadProgress(state, payload) {
      const commentableIndex = payload.commentableIndex
      const percentProgress = Math.round(payload.loaded / payload.total * 100)
      state.list[commentableIndex].inProgressPercentage = percentProgress
      state.list[commentableIndex].inProgress = payload.inProgress
    }
  },
  actions: {
    refreshComment(context, payload) {
      let comment = context.state.list[payload.commentableIndex].comments[payload.commentIndex]

      this.$http.get(`/comments/${comment.id}.json`).then(resp => {
        // Will fill in the mentions
        let newComment = resp.data
        newComment.showForm = false

        context.commit('updateComment', {
          commentableIndex: payload.commentableIndex,
          commentIndex: payload.commentIndex,
          data: newComment
        })
      }).catch(error => { console.log(error) })
    },
    removeComment(context, payload) {
      this.$http.delete(payload.url).then(resp => {
        context.commit('removeComment', payload)
      }).catch(error => {
        console.log(error)
      })
    },
    submitComment(context, payload) {
      let commentableIndex = payload.commentableIndex // the thing being commented on
      let hasFilesAttached = false

      const formData = new FormData()
      formData.append('comment[body]', payload.body)
      formData.append('comment[commentable_id]', context.state.list[commentableIndex].id)
      formData.append('comment[commentable_type]', payload.commentableType)

      if (payload.files) {
        for (var i = payload.files.length - 1; i >= 0; i--) {
          formData.append('comment[files][]', payload.files[i])
        }

        if (payload.files.length > 0) {
          hasFilesAttached = true
        }
      }

      const config = {
        onUploadProgress: progressEvent => {
          context.commit('updateUploadProgress', {
            commentableIndex,
            inProgress: hasFilesAttached,
            loaded: progressEvent.loaded,
            total: progressEvent.total
          })
        }
      }

      // Add a temporary comment
      context.commit('addTemporaryComment', {
        commentableIndex,
        data: {
          "body": payload.body,
          "can_edit": false,
          "created_at": null,
          "edited": false,
          "files": [],
          "id": null,
          "owner_name": context.rootGetters['signedInUser'],
          "processed_body": payload.body,
          "showForm": false,
          "updated_at": null
        }
      })

      this.$http.post(`/comments`, formData, config).then(resp => {
        const payload = { commentableIndex, data: resp.data }
        // Update temporary comment
        context.commit('removeLastComment', payload)
        context.commit('addNewComment', payload)
        context.commit('checklist/addAssignedUsers', payload.data.mentions, { root: true })
      }).catch(error => {
        // Remove the temporary comment
        context.commit('removeLastComment', { commentableIndex })
        console.log(error)
      })
    },
    toggleCommentEditForm(context, payload) {
      let comment = context.state.list[payload.commentableIndex].comments[payload.commentIndex]
      comment.showForm = !comment.showForm

      context.commit('updateComment', {
        commentableIndex: payload.commentableIndex,
        commentIndex: payload.commentIndex,
        data: comment
      })
    },
    updateComment(context, payload) {
      let comment = context.state.list[payload.commentableIndex].comments[payload.commentIndex]

      // Remove files that are ID'ed
      for (let i = payload.fileIdsToRemove.length - 1; i >= 0; i--) {
        const fileId = payload.fileIdsToRemove[i]
        this.$http.delete(`/comments/${comment.id}/purge_file?signed_id=${fileId}`).then(resp => {
          context.dispatch('refreshComment',{
            commentableIndex: payload.commentableIndex,
            commentIndex: payload.commentIndex,
          })
        })
      }
      comment.files = comment.files.filter(function(f){
        return !payload.fileIdsToRemove.includes(f.signed_id)
      })

      // Untoggle showForm
      comment.showForm = false
      comment.processed_body = payload.body

      // Temporary data so that it feels like real-time
      context.commit('updateComment', {
        commentableIndex: payload.commentableIndex,
        commentIndex: payload.commentIndex,
        data: comment
      })

      // Update the comment
      this.$http.patch(`/comments/${comment.id}`, {
        body: payload.body
      }).then(resp => {
        // Will fill in the mentions
        let newComment = resp.data
        newComment.showForm = false

        context.commit('updateComment', {
          commentableIndex: payload.commentableIndex,
          commentIndex: payload.commentIndex,
          data: newComment
        })
      }).catch(error => { console.log(error) })
    },
    updateExpandedComment(context, payload) {
      context.commit('updateExpandedComment', payload)
    }
  }
}
