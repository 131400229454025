var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "steps-segment has-gaps",
      class: { "is-active": _vm.active },
    },
    [
      _c("a", {
        staticClass: "steps-marker",
        on: {
          click: function ($event) {
            return _vm.updatePane(_vm.step)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "steps-content" }, [
        _c(
          "p",
          {
            staticClass: "heading",
            on: {
              click: function ($event) {
                return _vm.updatePane(_vm.step)
              },
            },
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }