var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comment" }, [
    _c(
      "div",
      { staticClass: "comment-container" },
      [
        _c("div", { staticClass: "comment-header" }, [
          _c("span", [
            _c(
              "a",
              {
                staticClass: "has-text-weight-bold",
                attrs: { href: _vm.ownerURL },
              },
              [_vm._v(_vm._s(_vm.ownerName))]
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _c(
              "a",
              {
                staticClass: "has-text-grey-light is-size-7",
                attrs: { href: _vm.commentURL },
              },
              [_c("timestamp", { attrs: { date: this.comment.updated_at } })],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.edited
            ? _c("span", { staticClass: "is-size-7 has-text-grey-light" }, [
                _vm._v("(edited)"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "no-wrap" }, [
            _vm.canEdit
              ? _c(
                  "a",
                  {
                    staticClass: "comment-edit-button is-size-7",
                    on: { click: _vm.toggleEditForm },
                  },
                  [_vm._v("edit")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.canEdit
              ? _c(
                  "a",
                  {
                    staticClass: "is-size-7",
                    on: { click: _vm.removeComment },
                  },
                  [_vm._v("delete")]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        this.showForm
          ? _c("edit-comment", {
              attrs: {
                "commentable-index": this.commentableIndex,
                "commentable-type": this.commentableType,
                "comment-index": this.commentIndex,
              },
            })
          : _c(
              "div",
              [
                _c("markdown", { attrs: { src: this.comment.processed_body } }),
                _vm._v(" "),
                _c("file-attachments", {
                  attrs: { files: this.comment.files },
                }),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }