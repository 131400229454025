var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box content" },
    [
      _c("h3", { staticClass: "is-size-5 has-text-weight-semibold" }, [
        _vm._v("\n    Templates\n  "),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    In the past, this knowledge likely lived on an internal wiki or shared drive.\n  "
        ),
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("next-pane"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n    A "),
      _c("strong", [_vm._v("template")]),
      _vm._v(
        " defines a repeatable process.\n    Some teams may call this a protocol.\n  "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { staticClass: "tag is-warning" }, [_vm._v("Example")]),
      _vm._v("\n    A "),
      _c("strong", [_vm._v("template")]),
      _vm._v(
        " specifies a standard series of steps to follow\n    when onboarding a new employee or contractor.\n  "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }