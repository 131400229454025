var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("datepicker", {
        attrs: {
          "input-class": "input",
          name: "checklist[deadline]",
          "use-utc": "true",
          format: "MMM dd, yyyy",
        },
        model: {
          value: this.val,
          callback: function ($$v) {
            _vm.$set(this, "val", $$v)
          },
          expression: "this.val",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }