var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checklist-easter-egg" },
    [
      _c("action-buttons", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.animating,
            expression: "!animating",
          },
        ],
        attrs: { cta: this.cta },
        on: { toggleAnimation: _vm.beginAnimation },
      }),
      _vm._v(" "),
      _c("progress", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showProgress,
            expression: "showProgress",
          },
        ],
        staticClass: "progress is-large is-success",
        attrs: { max: "100" },
        domProps: { value: this.progressValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }