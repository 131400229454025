var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "steps has-content-centered" },
    [
      _c("progress-button", { attrs: { step: "0" } }, [
        _vm._v("\n    Overview\n  "),
      ]),
      _vm._v(" "),
      _c("progress-button", { attrs: { step: "1" } }, [
        _vm._v("\n    Templates\n  "),
      ]),
      _vm._v(" "),
      _c("progress-button", { attrs: { step: "2" } }, [
        _vm._v("\n    Checklists\n  "),
      ]),
      _vm._v(" "),
      _c("progress-button", { attrs: { step: "3" } }, [
        _vm._v("\n    Teams\n  "),
      ]),
      _vm._v(" "),
      _c("progress-button", { attrs: { step: "4" } }, [
        _vm._v("\n    Get started\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }