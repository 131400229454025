var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box content" },
    [
      _c("h3", { staticClass: "is-size-5 has-text-weight-semibold" }, [
        _vm._v("\n    Teams\n  "),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("next-pane"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n    Teams are used to manage who has access to "),
      _c("strong", [_vm._v("templates")]),
      _vm._v(" and their associated "),
      _c("strong", [_vm._v("checklists")]),
      _vm._v(".\n  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { staticClass: "tag is-warning" }, [_vm._v("Example")]),
      _vm._v(
        "\n    Create a team for people in HR responsible for onboarding.\n  "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { staticClass: "tag is-info" }, [_vm._v("Tip")]),
      _vm._v(
        "\n    If your team does not have a consistent process yet, create a blank checklist and\n    add tasks as you go along.\n    Then, if the checklist works well, you can turn it into a template.\n  "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }