var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-inline" },
    [
      _vm._t(
        "trigger",
        function () {
          return [
            _c(
              "a",
              { staticClass: "info-button", on: { click: _vm.toggleModal } },
              [_c("i", { staticClass: "fas fa-info-circle" })]
            ),
          ]
        },
        { toggleModal: _vm.toggleModal }
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal has-text-weight-normal",
          class: { "is-active": this.active },
        },
        [
          _c("div", {
            staticClass: "modal-background",
            on: { click: _vm.toggleModal },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-card" }, [
            _c("header", { staticClass: "modal-card-head" }, [
              _c("p", { staticClass: "modal-card-title" }, [
                _vm._v("\n          " + _vm._s(this.title) + "\n        "),
              ]),
              _vm._v(" "),
              _c("a", {
                staticClass: "delete",
                attrs: { "aria-label": "close" },
                on: { click: _vm.toggleModal },
              }),
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "modal-card-body" }, [
              _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
            ]),
            _vm._v(" "),
            _c(
              "footer",
              { staticClass: "modal-card-foot" },
              [
                _vm._t("footer", null, { toggleModal: _vm.toggleModal }),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "button", on: { click: _vm.toggleModal } },
                  [_vm._v(_vm._s(this.buttonText || "Close"))]
                ),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }