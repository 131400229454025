var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldRender
    ? _c(
        "span",
        { staticClass: "tag has-text-weight-bold", class: _vm.classObject },
        [_vm._v("\n  " + _vm._s(_vm.displayText) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }