import { render, staticRenderFns } from "./favoriteButton.vue?vue&type=template&id=3df7381e&scoped=true&"
import script from "./favoriteButton.vue?vue&type=script&lang=js&"
export * from "./favoriteButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df7381e",
  null
  
)

export default component.exports