var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template-item-pane" },
    [
      _c("instructions"),
      _vm._v(" "),
      _c(
        "draggable",
        { attrs: { list: _vm.templateItemsAttributes, handle: ".draggable" } },
        _vm._l(_vm.templateItemsAttributes, function (templateItem, index) {
          return _c("template-item-form", {
            key: index,
            ref: "templateItem",
            refInFor: true,
            attrs: {
              templateItem: templateItem,
              index: index,
              errors: _vm.errors,
              "autocomplete-users": _vm.autocompleteUsers,
            },
            on: {
              "add-new-template-item": _vm.addTemplateItem,
              "move-up": function ($event) {
                return _vm.moveTemplateItemUp(index)
              },
              "move-down": function ($event) {
                return _vm.moveTemplateItemDown(index)
              },
              "remove-item": function ($event) {
                return _vm.removeTemplateItem(index)
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }