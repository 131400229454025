var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template-status-label-pane" },
    [
      _c("instructions"),
      _vm._v(" "),
      _c(
        "draggable",
        {
          attrs: {
            list: _vm.templateStatusLabelsAttributes,
            handle: ".draggable",
          },
        },
        _vm._l(
          _vm.templateStatusLabelsAttributes,
          function (statusLabel, index) {
            return _c("status-label-form", {
              key: index,
              ref: "statusLabel",
              refInFor: true,
              attrs: {
                statusLabel: statusLabel,
                index: index,
                errors: _vm.errors,
              },
              on: {
                "add-new-status-label": _vm.addStatusLabel,
                "move-down": function ($event) {
                  return _vm.moveDown(index)
                },
                "move-up": function ($event) {
                  return _vm.moveUp(index)
                },
                remove: function ($event) {
                  return _vm.removeStatusLabel(index)
                },
              },
            })
          }
        ),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }