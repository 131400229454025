var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "button is-white level-item",
      on: { click: _vm.toggleComments },
    },
    [
      _c("span", { staticClass: "icon" }, [
        _vm.expanded
          ? _c("i", { staticClass: "fa fa-eye-slash" })
          : _c("i", { staticClass: "fa fa-eye" }),
      ]),
      _vm._v(" "),
      _c("span", [_vm._v("\n    All Comments\n  ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }