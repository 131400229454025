window.addEventListener('turbolinks:load', function() {
  var el = document.getElementById("team_name");
  if (el) {
    el.addEventListener("focusout", function(event) {
      var el2 = document.getElementById("team_slug");
      if (el2.value) {
        if (el2.value.length === 0) {
          el2.value = event.target.value.replace(/ /g, "-").toLowerCase();
        }
      }
    });
  }
});
