var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "action-buttons" }, [
    _c("div", { staticClass: "buttons no-bottom" }, [
      _c(
        "button",
        {
          staticClass: "is-link button",
          class: { "is-loading": _vm.loading },
          on: { click: _vm.saveTemplate },
        },
        [_vm._m(0), _vm._v(" "), _c("span", [_vm._v("Save Template")])]
      ),
      _vm._v(" "),
      this.pane == 0
        ? _c(
            "button",
            {
              staticClass: "is-light button",
              on: { click: _vm.addTemplateItem },
            },
            [_vm._m(1), _vm._v(" "), _c("span", [_vm._v("Add item")])]
          )
        : _vm._e(),
      _vm._v(" "),
      this.pane == 1
        ? _c(
            "button",
            {
              staticClass: "is-light button",
              on: { click: _vm.addStatusLabel },
            },
            [_vm._m(2), _vm._v(" "), _c("span", [_vm._v("Add status label")])]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.errorsExist
      ? _c("p", { staticClass: "help is-danger" }, [
          _vm._v("\n    This template could not be saved. Please edit.\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-save" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-plus", attrs: { "aria-hidden": "true" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-plus", attrs: { "aria-hidden": "true" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }