var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box content" },
    [
      _c("h3", { staticClass: "is-size-5 has-text-weight-semibold" }, [
        _vm._v("\n    Checklists\n  "),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("next-pane"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Checklists")]),
      _vm._v(" can be created using a "),
      _c("strong", [_vm._v("template")]),
      _vm._v(
        " to ensure\n    that a standard series of steps is followed.\n  "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n    Each "),
      _c("strong", [_vm._v("checklist")]),
      _vm._v(
        " keeps track of which steps have been completed, when, and by whom.\n  "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { staticClass: "tag is-warning" }, [_vm._v("Example")]),
      _vm._v("\n    Create a new "),
      _c("strong", [_vm._v("checklist")]),
      _vm._v(
        " for each new employee's onboarding process.\n    This will ensure that your team doesn't forget any of the steps defined in the "
      ),
      _c("strong", [_vm._v("template")]),
      _vm._v(".\n  "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }