var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "media" }, [
    _c("div", { staticClass: "media-left" }, [
      _c(
        "div",
        {
          staticClass: "is-size-5 checklist_box",
          on: { click: _vm.toggleChecklistItem },
        },
        [
          _vm.checklistItem.complete
            ? _c("i", { staticClass: "fa fa-check-square" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.checklistItem.complete && _vm.canBeChecked
            ? _c("i", { staticClass: "far fa-square" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.checklistItem.complete && !_vm.canBeChecked
            ? _c("i", {
                staticClass: "fas fa-square has-text-grey-light cannot-check",
                attrs: { title: this.mandatoryMsg },
              })
            : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "media-content" },
      [
        _c(
          "p",
          {
            staticClass: "is-size-5 template-item-name",
            class: {
              "has-text-grey-light has-strikethrough":
                _vm.checklistItem.complete,
            },
          },
          [
            _vm._v("\n      " + _vm._s(_vm.templateItem.name) + "\n      "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.templateItem.mandatory,
                    expression: "templateItem.mandatory",
                  },
                ],
              },
              [_vm._v("*")]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.templateItem.processed_description && !_vm.checklistItem.complete
          ? _c(
              "div",
              {
                staticClass: "has-text-weight-light template-item-description",
              },
              [
                _c("markdown", {
                  attrs: { src: _vm.templateItem.processed_description },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.checklistItem.complete && _vm.checklistItem.owner_name
          ? _c(
              "p",
              { staticClass: "has-text-weight-light" },
              [
                _vm._v("\n      Completed by:\n      "),
                _c(
                  "a",
                  {
                    staticClass: "has-text-weight-bold",
                    attrs: { href: _vm.profileUrl },
                  },
                  [_vm._v(_vm._s(_vm.checklistItem.owner_name))]
                ),
                _vm._v("\n      on\n      "),
                _c("timestamp", {
                  attrs: { date: this.checklistItem.updated_at },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("checklist-item-comments", { attrs: { index: _vm.index } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }