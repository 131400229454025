var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message is-danger between-fields" }, [
    _c("div", { staticClass: "message-body content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n      We strongly recommend creating a\n      "),
        _c("a", { attrs: { href: _vm.derivedTemplateUrl } }, [
          _vm._v("new derived template"),
        ]),
        _vm._v("\n      instead.\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { staticClass: "tag is-danger" }, [_vm._v("Warning")]),
      _vm._v(
        "\n      This template is already in use. Changes could break existing\n      checklists based on this template, and will lead to the loss of comment\n      data if items are removed.\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }