var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "textarea",
    {
      ref: "textbox",
      staticClass: "textarea",
      attrs: {
        placeholder: this.placeholder,
        name: this.name,
        rows: this.rows,
      },
    },
    [_vm._v(_vm._s(this.body))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }