var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "formDiv", staticClass: "task-form" }, [
    this.showForm
      ? _c("div", [
          _c("div", { staticClass: "field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taskName,
                  expression: "taskName",
                },
              ],
              ref: "textbox",
              staticClass: "input",
              attrs: { placeholder: _vm.placeholderText },
              domProps: { value: _vm.taskName },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submitTask.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.taskName = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "control buttons action-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "button is-info is-small",
                  attrs: { disabled: !_vm.activeButton },
                  on: { click: _vm.submitTask },
                },
                [_vm._v("\n          Add task\n        ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button is-small is-white",
                  on: { click: _vm.hideForm },
                },
                [_vm._v("Hide")]
              ),
            ]),
          ]),
        ])
      : _c("div", [
          _c("a", { on: { click: _vm.toggleForm } }, [_vm._v("Add Task")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }