import axios from 'axios'
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

import checklistModule from './checklist.js'
import checklistItemModule from './checklistItem.js'
import taskModule from './task.js'
import tutorialModule from './tutorial.js'

Vue.use(Vuex)

let store = new Vuex.Store({
  modules: {
    checklist: checklistModule,
    checklistItem: checklistItemModule,
    task: taskModule,
    tutorial: tutorialModule
  },
  state: {
  },
  mutations: {
  },
  getters: {
    signedInUser() {
      const el = document.getElementById('username')

      if (el != null) {
        return el.innerText
      }
      return false
    }
  }
})

store.$http = axios;

export { store };
