var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "level" }, [
    _c("div", { staticClass: "level-right" }, [
      _c("div", { staticClass: "level-item" }, [
        _c(
          "a",
          {
            staticClass: "has-text-grey-light",
            attrs: { href: _vm.watcherURL },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.favoriteData.favorite_count) +
                "\n        "
            ),
            _vm._m(0),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "favorite_btn button level-item",
          class: _vm.addedClass,
          on: {
            click: _vm.toggleFavorite,
            mouseover: _vm.mouseover,
            mouseout: _vm.mouseout,
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon has-text-grey-light" }, [
      _c("i", { staticClass: "fa fa-star" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }