var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checklist-easter-egg" },
    [
      this.animating
        ? _c("div", { staticClass: "balloon-container" }, [
            _c("div", { staticClass: "balloon" }),
            _vm._v(" "),
            _c("div", { staticClass: "balloon" }),
            _vm._v(" "),
            _c("div", { staticClass: "balloon" }),
            _vm._v(" "),
            _c("div", { staticClass: "balloon" }),
            _vm._v(" "),
            _c("div", { staticClass: "balloon" }),
            _vm._v(" "),
            _c("div", { staticClass: "balloon" }),
            _vm._v(" "),
            _c("div", { staticClass: "balloon" }),
            _vm._v(" "),
            _c("div", { staticClass: "balloon" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("action-buttons", {
        attrs: { animating: this.animating, cta: this.cta },
        on: { toggleAnimation: _vm.toggleAnimation },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }