var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checklist-item-comments" }, [
    _vm.showComments
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "comment-list" },
              _vm._l(_vm.comments, function (comment, commentIndex) {
                return _c("comment", {
                  key: comment.id,
                  attrs: {
                    "comment-index": commentIndex,
                    "commentable-type": "item",
                    "commentable-index": _vm.index,
                  },
                })
              }),
              1
            ),
            _vm._v(" "),
            _vm.showCommentForm
              ? _c("comment-form", {
                  ref: "commentForm",
                  attrs: {
                    "commentable-index": _vm.index,
                    type: "checklistItem",
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.showCommentForm
      ? _c("div", { staticClass: "comment-buttons is-size-7" }, [
          _c("a", { on: { click: _vm.addComment } }, [_vm._v("Add comment")]),
          _vm._v(" "),
          this.comments.length > 0
            ? _c("span", [
                _vm._v("\n      |\n      "),
                !_vm.showComments
                  ? _c("a", { on: { click: _vm.toggleExpandedView } }, [
                      _vm._v(
                        "Show comments (" + _vm._s(this.comments.length) + ")"
                      ),
                    ])
                  : _c("a", { on: { click: _vm.toggleExpandedView } }, [
                      _vm._v("Hide comments"),
                    ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showNonApplicable
            ? _c("span", [
                _vm._v("\n      |\n      "),
                _c("a", { on: { click: _vm.nonApplicable } }, [
                  _vm._v("Not applicable"),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.showEscalateViaEmail
            ? _c("span", [
                _vm._v("\n      |\n      "),
                _c("a", { on: { click: _vm.flagChecklistItem } }, [
                  _c("span", [_vm._v("\n          Flag item\n        ")]),
                  _vm._v(" "),
                  _vm._m(0),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ml-1" }, [
      _c("i", { staticClass: "far fa-flag" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }