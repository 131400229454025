var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.files.length > 0
    ? _c(
        "div",
        { staticClass: "comment-files tags" },
        _vm._l(_vm.files, function (file) {
          return _c("div", { staticClass: "tag" }, [
            _vm._m(0, true),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.downloadURL(file.signed_id, file.name),
                  target: "_blank",
                },
              },
              [_vm._v(_vm._s(file.name))]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "filesize has-text-weight-light" }, [
              _vm._v("\n      " + _vm._s(file.size) + "\n    "),
            ]),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-paperclip" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }