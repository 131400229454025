/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'vue_container' %> (and
// <%= stylesheet_pack_tag 'vue_container' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.

import axios from 'axios'
import Vue from 'vue/dist/vue.esm'

import Checklist from '../components/checklists/checklist.vue'
import ChecklistAssignment from '../components/checklists/assignment.vue'
import ChecklistDeadlinePicker from '../components/checklists/deadlinePicker.vue'
import ChecklistExpandComments from '../components/checklists/expandComments.vue'
import ChecklistProgress from '../components/checklists/progress.vue'
import FavoriteButton from '../components/shared/favoriteButton.vue'
import Markdown from '../components/shared/markdown.vue'
import ModalCard from '../components/shared/modalCard.vue'
import Overdue from '../components/checklists/overdue.vue'
import RecurringScheduleFields from '../components/recurringChecklists/scheduleFields.vue'
import SingleFileUpload from '../components/shared/singleFileUpload.vue'
import SlideUpDown from '../components/shared/slideUpDown.vue'
import TagTextarea from '../components/shared/tagTextarea.vue'
import TemplateForm from '../components/templates/form.vue'
import TutorialContainer from '../components/tutorial/container.vue'
Vue.component('checklist', Checklist)
Vue.component('checklist-assignment', ChecklistAssignment)
Vue.component('checklist-deadline-picker', ChecklistDeadlinePicker)
Vue.component('checklist-expand-comments', ChecklistExpandComments)
Vue.component('checklist-progress', ChecklistProgress)
Vue.component('favorite-button', FavoriteButton)
Vue.component('markdown', Markdown)
Vue.component('modal-card', ModalCard)
Vue.component('overdue', Overdue)
Vue.component('recurring-schedule-fields', RecurringScheduleFields)
Vue.component('single-file-upload', SingleFileUpload)
Vue.component('slide-up-down', SlideUpDown)
Vue.component('tag-textarea', TagTextarea)
Vue.component('template-form', TemplateForm)
Vue.component('tutorial-container', TutorialContainer)

import { store } from '../store'
import TurbolinksAdapter from 'vue-turbolinks'

Vue.use(TurbolinksAdapter)
Vue.config.productionTip = false
Vue.prototype.$http = axios

document.addEventListener('turbolinks:load', () => {
  axios.defaults.headers.common = {
    "Accept": "application/json, text/plain, */*",
    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    "X-Requested-With": "XMLHttpRequest"
  }

  var container = document.querySelector('[vue-enabled]')
  if (container != null) {
    const app = new Vue({
      store,
    }).$mount(container)
  }
})

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'vue_container' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the using turbolinks, install 'vue-turbolinks':
//
// yarn add 'vue-turbolinks'
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks';
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
