var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "panel" },
    [
      _c("p", { staticClass: "panel-heading" }, [_vm._v("Assignees")]),
      _vm._v(" "),
      _vm.assignmentPermission
        ? _c("div", { staticClass: "panel-block" }, [
            _c(
              "div",
              {
                staticClass:
                  "dropdown checklist-assignment-form control is-hoverable has-icons-left",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.username,
                      expression: "username",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "input is-small is-rounded dropdown-trigger",
                  attrs: { placeholder: "Assign to (username)" },
                  domProps: { value: _vm.username },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.submit.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.username = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                this.displayed_users_exist
                  ? _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        attrs: { id: "dropdown-menu", role: "menu" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "dropdown-content" },
                          _vm._l(_vm.displayed_users, function (user) {
                            return _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { rel: "nofollow" },
                                on: {
                                  click: function ($event) {
                                    return _vm.assignUser(user.value)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(user.key) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(this.assignedUsers, function (user, index) {
        return _c("assignment-row", {
          key: index,
          attrs: {
            user: user,
            canUnassign: _vm.unassignmentPermission,
            canUnassignSelf: _vm.unassignSelfPermission,
            path: _vm.path,
          },
        })
      }),
      _vm._v(" "),
      this.assignedUsers.length == 0
        ? _c("div", { staticClass: "panel-block" }, [
            _vm._m(1),
            _vm._v("\n    No users assigned\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("assign-me", {
        attrs: { canAssign: _vm.canAssignSelf, path: _vm.path },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-user", attrs: { "aria-hidden": "true" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "panel-icon" }, [
      _c("i", {
        staticClass: "fas fa-exclamation-triangle",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }