var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file", class: { "has-name": _vm.hasFilename } },
    [
      _c("label", { staticClass: "file-label" }, [
        _c("input", {
          ref: "file",
          staticClass: "file-input",
          attrs: { type: "file", name: this.name, accept: _vm.accept },
          on: { change: _vm.updateFilename },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "file-cta" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "file-label" }, [
            _vm._v("\n        " + _vm._s(this.prompt) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasFilename,
                expression: "hasFilename",
              },
            ],
            staticClass: "file-name no-max-width",
          },
          [_vm._v("\n      " + _vm._s(this.filename) + "\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "file-icon" }, [
      _c("i", { staticClass: "fas fa-upload" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }