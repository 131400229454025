import commentModule from './comment.js'

export default {
  namespaced: true,
  state: {
    list: [],
    // Schema:
    // {
    //   "checklist_item": {
    //     "complete": boolean,
    //     "owner_name": string,
    //     "updated_at": datetime
    //   },
    //   "comment_status": integer,
    //   "comments": [{
    //     "body": string,
    //     "can_edit": boolean,
    //     "created_at": datetime,
    //     "edited": boolean,
    //     "files": [],
    //     "id": string,
    //     "owner_name": string,
    //     "processed_body": string,
    //     "showForm": boolean,
    //     "updated_at": datetime
    //   }],
    //   "id": string,
    //   "inProgress": boolean,
    //   "inProgressPercentage": integer,
    //   "template_item": {
    //     "_destroy": boolean,
    //     "description": string,
    //     "id": string,
    //     "item_type": string,
    //     "mandatory": boolean,
    //     "name": string,
    //     "order": integer,
    //     "processed_description": string
    //   }
    // }
  },
  mutations: {
    ...commentModule.mutations,
    temporaryChecklistItemUpdate(state, payload) {
      state.list[payload.index].checklist_item.complete = payload.complete
      state.list[payload.index].checklist_item.owner_name = null
    },
    updateChecklistItem(state, payload) {
      state.list[payload.index].checklist_item = payload.data
    },
    updateItems(state, payload) {
      state.list = payload
    },
  },
  actions: {
    ...commentModule.actions,
    initialize(context, rawData) {
      let items = JSON.parse(rawData)
      const defaultStatus = context.rootGetters['checklist/defaultCommentStatus']

      for (var i = items.length - 1; i >= 0; i--) {
        let item = items[i]
        item.comment_status = defaultStatus(item.checklist_item.complete)

        if (item.template_item.mandatory && item.comments.length == 0) {
          item.comment_status = 2
        }

        item.inProgress = false
        item.inProgressPercentage = 0
      }

      context.commit('updateItems', items)
    },
    flag(context, index) {
      if (confirm('Are you sure that you want to flag this item? Emails will be sent to all team adiministrators.')) {
        context.dispatch('submitComment', {
          body: "This has been flagged for additional attention.",
          commentableType: "ChecklistItem",
          commentableIndex: index
        })

        context.dispatch('checklist/escalateViaEmail', {
          checklist_item_id: context.state.list[index].id
        }, {
          root: true
        })
      }
    },
    markNonApplicable(context, index) {
      if(!context.state.list[index].checklist_item.complete) {
        context.dispatch('toggleChecklistItem', index)
      }
      context.dispatch('submitComment', {
        body: "N/A",
        commentableType: "ChecklistItem",
        commentableIndex: index,
      })
      context.dispatch('updateExpandedComment', {
        commentableIndex: index,
        value: 1, // Show the comments
      })
    },
    sortList(context, payload) {
      let result = []
      if (payload === 0) {
        // based on template_item order attribute
        result = context.state.list.sort(function(a,b) {
          return (a.template_item.order - b.template_item.order)
        })
      } else {
        // based on (checklist_item.complete + checklist_item.updated_at)
        // then template_item order attribute

        result = context.state.list.sort(function(a,b) {
          if (a.checklist_item.complete && b.checklist_item.complete) {
            // both are complete
            if (a.checklist_item.updated_at < b.checklist_item.updated_at) {
              return -1
            } else {
              return 1
            }
          } else if (a.checklist_item.complete) {
            return -1
          } else if (b.checklist_item.complete) {
            return 1
          } else {
            return (a.template_item.order - b.template_item.order)
          }
        })
      }

      context.commit('updateItems', result)
    },
    toggleChecklistItem(context, index) {
      const id = context.state.list[index].id
      const complete = context.state.list[index].checklist_item.complete

      // For rollback
      const originalChecklistItem = {
        complete: context.state.list[index].checklist_item.complete,
        owner_name: context.state.list[index].checklist_item.owner_name,
        updated_at: context.state.list[index].checklist_item.updated_at
      }

      context.commit('temporaryChecklistItemUpdate', { index, complete: !complete })

      this.$http.patch(`/checklist_items/${id}`, {
        checklist_item: {
          complete: !complete
        }
      }).then(resp => {
        context.commit('updateChecklistItem', { index, data: resp.data })
      }).catch(error => {
        console.log(error)
        setTimeout(function() {
          context.commit('updateChecklistItem', { index, data: originalChecklistItem })
        }, 300)
      })
    },
  },
  getters: {
    checklistId(state, getters, rootState) {
      return rootState.checklist.id
    },
    total(state) {
      return state.list.length
    },
    totalComplete(state) {
      return state.list.filter((t) => t.checklist_item.complete == true).length
    }
  },
}
