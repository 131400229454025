var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template_vue_form" },
    [
      false
        ? _c(
            "div",
            { staticClass: "field" },
            [
              _c("div", { staticClass: "select" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.templateData.checklist_type,
                        expression: "templateData.checklist_type",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.templateData,
                          "checklist_type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v("Read-Do"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("Do-Confirm"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("info-modal"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          {
            staticClass:
              "column is-3-widescreen is-4-tablet is-12-mobile no-bottom",
          },
          [
            _c("div", { staticClass: "select is-fullwidth" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.templateOwner,
                      expression: "templateOwner",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.templateOwner = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.loadAutocompleteUsers,
                    ],
                  },
                },
                _vm._l(this.templateOwnerValues, function (ownerValue) {
                  return _c("option", { domProps: { value: ownerValue } }, [
                    _vm._v(
                      "\n            " + _vm._s(ownerValue) + "\n          "
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "column no-bottom" }, [
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.templateData.name,
                    expression: "templateData.name",
                  },
                ],
                ref: "templateName",
                staticClass: "input",
                class: { "is-danger": _vm.errorFor("name") },
                attrs: {
                  type: "text",
                  name: "template_name",
                  placeholder: "Template name",
                },
                domProps: { value: _vm.templateData.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.templateData, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errorFor("name"),
                    expression: "errorFor('name')",
                  },
                ],
                staticClass: "help is-danger",
              },
              [_vm._v("This cannot be blank")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("privacy-notice", {
        attrs: {
          templateOwner: _vm.templateOwner,
          templatePrivateOwnerValues: this.templatePrivateOwnerValues,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "control" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.templateData.description,
                expression: "templateData.description",
              },
            ],
            staticClass: "textarea",
            class: { "is-danger": _vm.errorFor("description") },
            attrs: {
              rows: "3",
              name: "description",
              placeholder: "Description (optional)",
            },
            domProps: { value: _vm.templateData.description },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.templateData, "description", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errorFor("description"),
                expression: "errorFor('description')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v("This cannot be blank")]
        ),
      ]),
      _vm._v(" "),
      _vm.templateData.id
        ? _c("existing-template-warning", {
            attrs: { derivedTemplateUrl: _vm.derivedTemplateUrl },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "tabs is-boxed" }, [
        _c("ul", [
          _c(
            "li",
            {
              class: { "is-active": _vm.currentPane(0) },
              on: {
                click: function ($event) {
                  return _vm.updatePane(0)
                },
              },
            },
            [_c("a", [_vm._v("Items")])]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { "is-active": _vm.currentPane(1) },
              on: {
                click: function ($event) {
                  return _vm.updatePane(1)
                },
              },
            },
            [_c("a", [_vm._v("Status Labels")])]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { "is-active": _vm.currentPane(2) },
              on: {
                click: function ($event) {
                  return _vm.updatePane(2)
                },
              },
            },
            [_c("a", [_vm._v("Preferences")])]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("template-item-pane", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentPane(0),
            expression: "currentPane(0)",
          },
        ],
        ref: "templateItemPane",
        attrs: {
          autocompleteUsers: this.autocompleteUsers,
          "template-items-attributes":
            this.templateData.template_items_attributes,
          errors: this.errors,
        },
      }),
      _vm._v(" "),
      _c("template-status-label-pane", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentPane(1),
            expression: "currentPane(1)",
          },
        ],
        ref: "templateStatusLabelPane",
        attrs: {
          "template-status-labels-attributes":
            this.templateData.template_status_labels_attributes,
          errors: this.errors,
        },
      }),
      _vm._v(" "),
      _c("template-preferences-pane", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentPane(2),
            expression: "currentPane(2)",
          },
        ],
        ref: "templatesPreferencesPane",
        attrs: {
          "template-preferences": this.templateData.template_preferences,
        },
      }),
      _vm._v(" "),
      _c("action-buttons", {
        attrs: {
          pane: this.pane,
          loading: this.loading,
          "errors-exist": _vm.errorsExist,
        },
        on: {
          "save-template": _vm.saveTemplate,
          "add-template-item": _vm.addTemplateItem,
          "add-status-label": _vm.addStatusLabel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }