var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box content" }, [
    _c("h3", { staticClass: "is-size-5 has-text-weight-semibold" }, [
      _vm._v("\n    Get started\n  "),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n    We've created your first Hoshon checklist to complete\n    to be sure that you have everything configured properly.\n  "
      ),
    ]),
    _vm._v(" "),
    _c("p", [_vm._v("\n    Great work!\n  ")]),
    _vm._v(" "),
    _c("div", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c(
          "a",
          {
            staticClass: "level-item button is-success",
            attrs: { href: _vm.checklistUrl },
          },
          [_vm._v("Take me to my checklist →")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }