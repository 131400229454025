export default {
  namespaced: true,
  state: {
    addressableUsers: [],
    // Schema:
    // {
    //   "key": string,
    //   "value": string
    // }
    assignedUsers: [],
    // Schema:
    // {
    //   "guest": boolean,
    //   "username": string
    // }
    canEdit: false,
    commentsAfterComplete: 'hidden',
    deadline: null,
    escalateViaEmail: "no",
    hasStatus: false,
    id: null
  },
  mutations: {
    addAssignedUsers(state, users) {
      // Add mentions to the assigned users
      for (var i = users.length - 1; i >= 0; i--) {
        const username = users[i].username

        const currentAssignedUsers = state.assignedUsers.map(function(u) {
          return u.username
        })

        if(!currentAssignedUsers.includes(username)) {
          let isGuest = state.addressableUsers.filter(function(u) {
            return u.value === username && u.value != u.key
          }).length > 0

          state.assignedUsers.push({ username: username, guest: isGuest })
        }
      }
    },
    addressableUsers(state, rawData) {
      const data = JSON.parse(rawData) || []

      state.addressableUsers = data.sort(function(a, b) {
        let keyA = a.key.toLowerCase()
        let keyB = b.key.toLowerCase()

        if (keyA < keyB) {
          return -1
        }
        if (keyA > keyB) {
          return 1
        }
        return 0
      })
    },
    initializeAssignedUsers(state, data) {
      state.assignedUsers = JSON.parse(data)
    },
    removeAssignedUser(state, payload) {
      const userIndex = state.assignedUsers.findIndex(function(user) {
        return user.username === payload.username
      })
      state.assignedUsers.splice(userIndex, 1)
    },
    updateChecklistBase(state, payload) {
      state.id = payload.id

      if (payload.canEdit) {
        state.canEdit = JSON.parse(payload.canEdit)
      } else {
        state.canEdit = false
      }

      if (payload.deadline) {
        state.deadline = Date.parse(payload.deadline)
      } else {
        state.deadline = null
      }

      if (payload.escalateViaEmail) {
        state.escalateViaEmail = payload.escalateViaEmail
      }

      state.hasStatus = JSON.parse(payload.hasStatus)
    },
    updateChecklistPreferences(state, payload) {
      state.commentsAfterComplete = payload.commentsAfterComplete
    },
  },
  actions: {
    addAssignedUsers(context, payload) {
      const url = `${payload.path}?users=${payload.users}`

      this.$http.post(url).then(resp => {
        context.commit('addAssignedUsers', resp.data)
      }).catch(error => {
        console.log(error)
      })
    },
    commentExpansion(context, payload) {
      context.commit('checklistItem/updateCommentStatus', payload.expanded, { root: true })
      context.commit('task/updateCommentStatus', payload.expanded, { root: true })
    },
    escalateViaEmail(context, payload) {
      this.$http.post(`/checklists/${context.state.id}/flag`, {
        checklist_item_id: payload.checklist_item_id
      }).then(resp => {
        // console.log(resp.data)
      }).catch(error => {
        console.log(error)
      })
    },
    initializeAssignmentForm(context, payload) {
      context.commit('initializeAssignedUsers', payload.assignedUsers)
      context.commit('addressableUsers', payload.addressableUsers)
    },
    initializeChecklist(context, payload) {
      context.commit('updateChecklistBase', {
        id: payload.id,
        canEdit: payload.canEdit,
        deadline: payload.deadline,
        escalateViaEmail: payload.escalateViaEmail,
        hasStatus: payload.hasStatus
      })
      context.commit('updateChecklistPreferences', {
        commentsAfterComplete: payload.commentsAfterComplete
      })
    },
    removeAssignedUser(context, payload) {
      this.$http.delete(payload.url).then(resp => {
        context.commit('removeAssignedUser', {
          username: payload.username
        })
      }).catch(error => {
        console.log(error)
      })
    },
    rotateSortOrder(context, payload) {
      context.dispatch('checklistItem/sortList', payload, { root: true })
      // TODO update the task order as well
    },
  },
  getters: {
    addressableUsers(state) {
      return state.addressableUsers
    },
    dropdownMenu(state, getters) {
      return function(username) {
        // Filter the possible users based on the username that has been entered
        return state.addressableUsers.filter(function(user){
          return !state.assignedUsers.map(function(u){
            return u.username
          }).includes(user.value)
        }).filter(function(user) {
          return user.value.toLowerCase().match(username.toLowerCase());
        })
      }
    },
    complete(state, getters) {
      const numItems = getters['totalItems'] === getters['totalCompleteItems']
      return numItems && getters['totalItems'] !== 0
    },
    defaultCommentStatus(state) {
      // set whether the comments should be shown
      // there are 3 states:
      // 0: hide comments, hide comment form
      // 1: show comments, hide comment form
      // 2: show comments, show comment form

      return function(complete) {
        if (complete && state.commentsAfterComplete === 'hidden') {
          return 0
        }
        return 1
      }
    },
    showEscalateViaEmail(state, getters, rootState, rootGetters) {
      const showEscalateViaEmail = state.escalateViaEmail == "yes"
      const signedIn = rootGetters['signedInUser'] != false

      return showEscalateViaEmail && signedIn
    },
    totalCompleteItems(state, getters, rootState, rootGetters) {
      const numChecklistItems = rootGetters['checklistItem/totalComplete']
      const numTasks = rootGetters['task/totalComplete']

      return numChecklistItems + numTasks
    },
    totalItems(state, getters, rootState, rootGetters) {
      const numChecklistItems = rootGetters['checklistItem/total']
      const numTasks = rootGetters['task/total']

      return numChecklistItems + numTasks
    }
  },
}
