var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "media" }, [
    _c("div", { staticClass: "media-left" }, [
      _c(
        "div",
        {
          staticClass: "is-size-5 checklist_box",
          on: { click: _vm.toggleChecklistTask },
        },
        [
          _vm.taskData.complete
            ? _c("i", { staticClass: "fa fa-check-square" })
            : _c("i", { staticClass: "far fa-square" }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "media-content" },
      [
        _vm.showForm
          ? _c("edit-task-form", { attrs: { index: this.index } })
          : _c(
              "p",
              {
                staticClass: "is-size-5 task-name draggable",
                class: {
                  "has-text-grey-light has-strikethrough":
                    _vm.taskData.complete,
                },
                on: {
                  mouseover: function ($event) {
                    _vm.hover = true
                  },
                  mouseleave: function ($event) {
                    _vm.hover = false
                  },
                },
              },
              [
                _vm._v("\n      " + _vm._s(_vm.taskData.name) + "\n\n      "),
                _c(
                  "span",
                  {
                    staticClass: "icon has-text-grey-light is-small",
                    class: { "is-hidden": !_vm.hover },
                    on: { click: _vm.toggleForm },
                  },
                  [_c("i", { staticClass: "fa fa-sm fa-pencil-alt" })]
                ),
              ]
            ),
        _vm._v(" "),
        _vm.taskData.complete && _vm.taskData.owner_name
          ? _c(
              "p",
              { staticClass: "has-text-weight-light" },
              [
                _vm._v("\n      Completed by:\n      "),
                _c(
                  "a",
                  {
                    staticClass: "has-text-weight-bold",
                    attrs: { href: _vm.profileUrl },
                  },
                  [_vm._v(_vm._s(_vm.taskData.owner_name))]
                ),
                _vm._v("\n      on\n      "),
                _c("timestamp", {
                  attrs: { date: this.taskData.completed_at },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("task-comments", { attrs: { index: _vm.index } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }