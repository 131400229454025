var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checklist-item-list" },
    _vm._l(_vm.checklistItemsData, function (checklistItem, index) {
      return _c("checklist-item", { key: index, attrs: { index: index } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }