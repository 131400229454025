var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-card",
    {
      attrs: { title: "Template types", "button-text": "Back" },
      scopedSlots: _vm._u([
        {
          key: "trigger",
          fn: function (scope) {
            return [
              _c(
                "a",
                {
                  staticClass:
                    "faq-button button is-white is-size-6 has-text-grey",
                  on: { click: scope.toggleModal },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-info-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]
          },
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "button is-link",
                  attrs: { href: "/faq#checklist-types", target: "_blank" },
                },
                [_vm._v("Learn more")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("p", [_vm._v("\n    Hoshon supports two types of templates.\n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticClass: "tag is-dark" }, [_vm._v("Read-Do")]),
        _vm._v(
          "\n    templates are like recipes that are intended to be completed in order.\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticClass: "tag is-dark" }, [_vm._v("Do-Confirm")]),
        _vm._v(
          "\n    templates are filled out after the fact to be sure that nothing was forgotten by accident.\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    This is meant to help users understand how to make the best use of the\n    checklist that they are using. There is no difference in functionality.\n  "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }