var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message between-fields" }, [
    _vm.isPrivate
      ? _c("div", { staticClass: "message-body" }, [
          _c("span", { staticClass: "tag is-warning" }, [_vm._v("Private")]),
          _vm._v("\n    This template will only be visible to\n    "),
          _c(
            "a",
            {
              attrs: {
                href: `/teams/${_vm.templateOwner}/members`,
                target: "_blank",
              },
            },
            [_vm._v("members")]
          ),
          _vm._v("\n    of the "),
          _c(
            "a",
            {
              attrs: { href: `/teams/${_vm.templateOwner}`, target: "_blank" },
            },
            [_vm._v("team")]
          ),
          _vm._v(".\n  "),
        ])
      : _c("div", { staticClass: "message-body" }, [
          _c("span", { staticClass: "tag is-success" }, [_vm._v("Public")]),
          _vm._v(
            "\n    This template will be shareable with all Hoshon users.\n  "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }