var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "button",
      class: { "is-link": _vm.isActive },
      on: { click: _vm.toggleValue },
    },
    [
      _vm._v("\n  " + _vm._s(this.label) + "\n  "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.fieldName },
        domProps: { value: _vm.isActive },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }