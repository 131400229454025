var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slide_up_down" },
    [
      _c("a", { on: { click: _vm.toggle } }, [
        _vm._v("\n    " + _vm._s(this.text) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "vue-slide-up-down",
        {
          staticClass: "slide_up_down_body",
          attrs: { active: this.active, duration: 250 },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }