import commentModule from './comment.js'

export default {
  namespaced: true,
  state: {
    list: [],
    // Schema:
    // {
    //   "comments": [{
    //     "body": string,
    //     "can_edit": boolean,
    //     "created_at": datetime,
    //     "edited": boolean,
    //     "files": [],
    //     "id": string,
    //     "owner_name": string,
    //     "processed_body": string,
    //     "showForm": boolean,
    //     "updated_at": datetime
    //   }],
    //   comment_status: integer,
    //   data: {
    //     complete: boolean,
    //     completed_at: datetime,
    //     id: string,
    //     name: string,
    //     order: integer,
    //     owner_name: string,
    //     updated_at: datetime
    //   },
    //   inProgress: boolean,
    //   inProgressPercentage: integer,
    //   showForm: boolean
    // }
  },
  mutations: {
    ...commentModule.mutations,
    addNewTask(state, payload) {
      state.list.push({
        comments: [],
        comment_status: 1, // show comments, but hide comment form
        data: payload,
        id: payload.id,
        inProgress: false,
        inProgressPercentage: 0,
        showForm: false
      })
    },
    removeTask(state, payload) {
      const index = payload
      state.list.splice(index,1)
    },
    temporaryTaskUpdate(state, payload) {
      // Before server confirmation
      state.list[payload.index].data.complete = payload.complete
      state.list[payload.index].data.owner_name = null

      if (payload.name != null && payload.name != "") {
        state.list[payload.index].data.name = payload.name
      }
    },
    updateShowForm(state, payload) {
      state.list[payload.index].showForm = payload.value
    },
    updateTaskData(state, payload) {
      // For toggling
      state.list[payload.index].data = payload.data
    },
    updateTasks(state, payload) {
      // For re-ordering and initialization
      state.list = payload
    },
  },
  actions: {
    ...commentModule.actions,
    initialize(context, rawData) {
      let tasks = JSON.parse(rawData)
      const defaultStatus = context.rootGetters['checklist/defaultCommentStatus']

      for (var i = tasks.length - 1; i >= 0; i--) {
        let task = tasks[i]
        task.comment_status = defaultStatus(task.data.complete)
        task.inProgress = false
        task.inProgressPercentage = 0
        task.showForm = false
      }

      context.commit('updateTasks', tasks)
    },
    removeTask(context, payload) {
      const taskIndex = payload.index

      this.$http.delete(payload.url).then(resp => {
        context.commit('removeTask', taskIndex)
      }).catch(error => {
        console.log(error)
      })
    },
    submitTask(context, payload) {
      const formData = new FormData()
      const checklistId = context.getters['checklistId']

      formData.append('task[name]', payload.name)
      formData.append('task[checklist_id]', checklistId)
      formData.append('task[order]', payload.order)

      this.$http.post(`/tasks`, formData).then(resp => {
        const taskPayload = resp.data
        context.commit('addNewTask', taskPayload)
      })
    },
    toggleTask(context, index) {
      const task = context.state.list[index]

      const id = task.data.id
      const complete = task.data.complete

      // For rollback
      const originalTask = Object.assign({}, task.data)

      context.commit('temporaryTaskUpdate', { index, complete: !complete })

      this.$http.patch(`/tasks/${id}`, {
        task: {
          complete: !complete
        }
      }).then(resp => {
        context.commit('updateTaskData', { index, data: resp.data })
      }).catch(error => {
        console.log(error)
        setTimeout(function() {
          context.commit('updateTaskData', { index, data: originalChecklistTask })
        }, 300)
      })
    },
    updateTask(context, payload) {
      const index = payload.index
      const task = context.state.list[index]

      // For rollback
      const originalTask = Object.assign({}, task.data)
      context.commit('temporaryTaskUpdate', { index, name: payload.name })

      this.$http.patch(`/tasks/${task.id}`, {
        task: {
          name: payload.name
        }
      }).then(resp => {
        context.commit('updateTaskData', { index, data: resp.data })
      }).catch(error => {
        setTimeout(function() {
          context.commit('updateTaskData', { index, data: originalChecklistTask })
        }, 300)
        console.log(error)
      })

      context.commit('updateShowForm', { index, value: false })
    },
    updateList(context, payload) {
      const originalState = context.state.list
      const checklistId = context.getters['checklistId']

      let tasksAttributes = []

      // Update the state with the right order parameter
      for (var i = payload.length - 1; i >= 0; i--) {
        payload[i].data.order = i
        tasksAttributes.push({
          id: payload[i].id,
          order: i
        })
      }
      context.commit('updateTasks', payload)

      // Send an ajax request to update the db
      this.$http.patch(`/checklists/${checklistId}/tasks`, {
        checklist: {
          tasks_attributes: tasksAttributes
        }
      }).then(resp => {
        if (resp.status != 200) {
          context.commit('updateTasks', originalState)
        }
      }).catch(error => {
        console.log(error)
        setTimeout(function() {
          context.commit('updateTasks', originalState)
        }, 300)
      })
    },
    updateShowForm(context, payload) {
      context.commit('updateShowForm', payload)
    }
  },
  getters: {
    checklistId(state, getters, rootState) {
      return rootState.checklist.id
    },
    maxTaskOrder(state) {
      const taskLength = state.list.length
      if (taskLength == 0) {
        return taskLength
      } else {
        const lastTask = state.list[taskLength - 1]
        return lastTask.data.order
      }
    },
    total(state) {
      return state.list.length
    },
    totalComplete(state) {
      return state.list.filter((t) => t.data.complete == true).length
    }
  },
}
