var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "template-preferences-pane" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v(
          "\n      Send emails to admins if there's an issue completing a step\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "control" }, [
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.templatePreferences.escalate_via_email,
                  expression: "templatePreferences.escalate_via_email",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.templatePreferences,
                    "escalate_via_email",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "yes" } }, [_vm._v("Yes")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "no" } }, [_vm._v("No")]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "message" }, [
      _c("div", { staticClass: "message-body content" }, [
        _c("p", [
          _vm._v(
            "\n        These settings determine the how many emails managers will receive\n        about a checklist's progress.\n      "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }