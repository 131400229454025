var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "checklist-complete" } },
    [
      this.easterEgg == 0
        ? _c("progressBar", { attrs: { cta: this.cta } })
        : _vm._e(),
      _vm._v(" "),
      this.easterEgg == 1
        ? _c("confetti", { attrs: { cta: this.cta } })
        : _vm._e(),
      _vm._v(" "),
      this.easterEgg == 2
        ? _c("fireworks", { attrs: { cta: this.cta } })
        : _vm._e(),
      _vm._v(" "),
      this.easterEgg == 3
        ? _c("balloons", { attrs: { cta: this.cta } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }