var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-list" },
    [
      _c(
        "draggable",
        {
          attrs: { handle: ".draggable" },
          model: {
            value: _vm.tasksData,
            callback: function ($$v) {
              _vm.tasksData = $$v
            },
            expression: "tasksData",
          },
        },
        _vm._l(_vm.tasksData, function (task, index) {
          return _c("task", { key: index, attrs: { index: index } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }