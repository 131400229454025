var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "buttons" }, [
    _c(
      "button",
      {
        staticClass: "button",
        class: { "is-success": !this.animating, "is-danger": this.animating },
        on: { click: _vm.toggleAnimation },
      },
      [_vm._v("\n    " + _vm._s(this.cta) + "\n  ")]
    ),
    _vm._v(" "),
    _vm.showEdit
      ? _c("a", { staticClass: "button", attrs: { href: _vm.editURL } }, [
          _vm._v("Update Status"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }