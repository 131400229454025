var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    { staticClass: "panel-block", attrs: { href: _vm.profileURL } },
    [
      _vm._m(0),
      _vm._v("\n  " + _vm._s(this.user.username) + "\n  "),
      this.user.guest
        ? _c("span", { staticClass: "is-guest tag is-warning" }, [
            _vm._v("Guest"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.unassignable
        ? _c("span", { staticClass: "horizontal-spacer" })
        : _vm._e(),
      _vm._v(" "),
      _vm.unassignable
        ? _c("a", {
            staticClass: "delete is-small",
            attrs: { rel: "nofollow" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.unassign.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "panel-icon" }, [
      _c("i", { staticClass: "fas fa-user", attrs: { "aria-hidden": "true" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }